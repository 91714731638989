import truncate from "lodash.truncate";

import { META_DESC_MAX_LENGTH, META_TITLE_MAX_LENGTH } from "./globalConst";
import { getEpochEndingYear } from "./timeMachineUtils";

export const titleSuffix = " | Nostwave";

export function truncateTitle(text: string) {
    return truncateText(text, META_TITLE_MAX_LENGTH);
}

export function truncateText(text: string, maxLength = META_DESC_MAX_LENGTH) {
    return truncate(text, { length: maxLength, separator: " " });
}

export function yearsFromToText(year: number): string {
    return `years ${year}-${getEpochEndingYear(year)}`;
}
