import { TopicDto } from "../../../topics/models/topicDto";
import { TopicCategory } from "../enums/topicCategory";

export const topicNameMap: Record<TopicCategory, string> = {
    [TopicCategory.VideoGame]: "Video game",
    [TopicCategory.Movie]: "Movie",
    [TopicCategory.Music]: "Music",
    [TopicCategory.TvSeries]: "TV Series",
    [TopicCategory.Book]: "Book",
    [TopicCategory.Company]: "Company",
    [TopicCategory.CPU]: "Processor (CPU)",
    [TopicCategory.GPU]: "Graphics card (GPU)",
    [TopicCategory.MobileDevice]: "Mobile device",
    [TopicCategory.VideoGameConsole]: "Video Game Console",
    [TopicCategory.Computer]: "Computer",
    [TopicCategory.OS]: "Operating System",
    [TopicCategory.Software]: "Software",
    [TopicCategory.Person]: "Person",
    [TopicCategory.Country]: "Country",
    [TopicCategory.Football]: "Football",
};

export class TopicView extends TopicDto {
    public static categoryName(dto: TopicDto): string {
        return topicNameMap[dto.category];
    }
}
