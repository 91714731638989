import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Sentry from "@sentry/nextjs";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";

import { AppPath } from "../../../appPath";
import { useUser } from "../../../auth/authContext";
import { PrivateLink } from "../../../auth/components/PrivateLink";
import { authService } from "../../../auth/services/authService";
import { useYear } from "../../hooks/useYear";
import { Avatar } from "../Avatar";
import { NostwaveLogo } from "../NostwaveLogo";
import { SearchBox } from "../SearchBox";

export const MainNavbar: FC = () => {
    const { user, signOut } = useUser();
    const router = useRouter();
    const year = useYear();

    async function handleSignOut() {
        if (router.asPath !== "/") {
            router.replace("/");
        }
        authService.signOut().catch((error) => {
            Sentry.captureException(error);
        });
        signOut();
    }

    return (
        <Navbar className="navbar-light border-bottom" bg="white" expand="lg">
            <Container>
                <Navbar.Brand>
                    <NostwaveLogo link="/" size="6rem" />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="basic-navbar-nav" />

                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="pt-3 pt-lg-0">
                        <SearchBox />
                    </Nav>

                    <div className="pt-3 pt-lg-0 ms-2 d-flex align-items-center flex-grow-1 justify-content-between">
                        <Nav>
                            <PrivateLink href={AppPath.writePost} passHref>
                                <a className="nav-link">
                                    <FontAwesomeIcon className="me-1" icon={faPencilAlt} /> Write
                                </a>
                            </PrivateLink>
                        </Nav>

                        {user && (
                            <div className="d-flex flex-row align-items-center">
                                <Nav className="clickable">
                                    <Link href={AppPath.userProfile(year, user.username)} passHref>
                                        <a className="d-inline-flex align-items-center nav-link">
                                            <Avatar src={user.avatarUrls[year]} size="small" username={user.username} />
                                            <span className="ms-2">{user.username}</span>
                                        </a>
                                    </Link>
                                </Nav>
                                <NavDropdown title="" id="user-dropdown" align="end">
                                    <NavDropdown.Item href={AppPath.userProfile(year, user.username)}>See your profile</NavDropdown.Item>
                                    <NavDropdown.Item href={AppPath.accountSettings}>Settings</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item onClick={handleSignOut}>Sign out</NavDropdown.Item>
                                </NavDropdown>
                            </div>
                        )}

                        {!user && (
                            <>
                                <Nav className="ms-auto">
                                    <Nav.Link as={Link} href={AppPath.signIn()} passHref>
                                        <a className="nav-link">Sign in</a>
                                    </Nav.Link>
                                </Nav>
                                <Nav className="ms-3 ms-lg-0">
                                    <Nav.Link as={Link} href={AppPath.signUp} passHref>
                                        <a className="nav-link">Sign up</a>
                                    </Nav.Link>
                                </Nav>
                            </>
                        )}
                    </div>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};
