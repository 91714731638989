import classNames from "classnames";
import { FC, useEffect, useState } from "react";
import Image from "react-bootstrap/Image";

import { BaseProps } from "../models/props/baseProps";
import styles from "./Avatar.module.scss";

type SizeType = "small" | "medium" | "large";

interface Props extends BaseProps {
    src: string;
    username: string;
    size?: SizeType;
}

const colors = ["#7e3794", "#67ae3f", "#007bff", "#e55d4a", "#fcce09", "#30b378", "#30a4b3", "#343a40"];

function usernameToColor(username: string): string {
    let sum = 0;
    for (let i = 0; i < username.length; i++) {
        sum += username.charCodeAt(i);
    }
    return colors[sum % colors.length];
}

export const Avatar: FC<Props> = ({ src, size, username, className }) => {
    const [displayDefault, setDisplayDefault] = useState(!src);

    useEffect(() => {
        setDisplayDefault(!src);
    }, [src]);

    const sizeClass = size
        ? [{ [styles.small]: size === "small" }, { [styles.medium]: size === "medium" }, { [styles.large]: size === "large" }]
        : [];

    if (displayDefault) {
        return (
            <svg className={classNames(...sizeClass, className)} viewBox="0, 0, 100, 100" width="100%">
                <circle cx="50" cy="50" r="49" fill={usernameToColor(username)} />
                <text x="50" y="71.5" fontSize="60" textAnchor="middle" fill="#fff">
                    {username[0].toUpperCase()}
                </text>
            </svg>
        );
    }

    return (
        <Image
            className={classNames(...sizeClass, "img-cover", className)}
            src={src}
            alt={username + " avatar"}
            onError={() => setDisplayDefault(true)}
            roundedCircle
            fluid
        />
    );
};
