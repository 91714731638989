import { faFacebook, faInstagram, faTiktok, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Link from "next/link";
import React, { FC, useState } from "react";
import Container from "react-bootstrap/Container";

import { AppPath } from "../../../appPath";
import { AboutUsModal } from "../../modals/AboutUsModal";
import { NostwaveLogo } from "../NostwaveLogo";
import styles from "./AppFooter.module.scss";

const year = new Date().getFullYear();

export const AppFooter: FC = () => {
    const [showAboutUs, setShowAboutUs] = useState(false);

    return (
        <>
            <AboutUsModal show={showAboutUs} onHide={() => setShowAboutUs(false)} />

            <footer className={styles.footer}>
                <Container className={`${styles.container} clearfix`}>
                    <div className="d-flex flex-row">
                        <div className={`${styles.summary} pe-2`}>
                            <NostwaveLogo link="/" size="6rem" />

                            <blockquote className="mt-3">
                                <p>Sometimes you will never know the value of a moment until it becomes a memory.</p>
                                <footer className="blockquote-footer">
                                    <cite title="Theodor Seuss Geisel">Theodor Seuss Geisel</cite>
                                </footer>
                            </blockquote>

                            <div className={styles["social-links"]}>
                                <a target="_blank" rel="noopener" href="https://www.youtube.com/channel/UCziEnrtdwiMFTBaIkf-st1Q">
                                    <FontAwesomeIcon className="icon-youtube" icon={faYoutube} fixedWidth />
                                </a>

                                <a target="_blank" rel="noopener" href="https://www.facebook.com/nostwaveofficial/">
                                    <FontAwesomeIcon className="icon-facebook" icon={faFacebook} fixedWidth />
                                </a>

                                <a target="_blank" rel="noopener" href="https://www.instagram.com/nostwaveofficial/">
                                    <FontAwesomeIcon className="icon-instagram" icon={faInstagram} fixedWidth />
                                </a>

                                <a target="_blank" rel="noopener" href="https://twitter.com/nostwave">
                                    <FontAwesomeIcon className="icon-twitter" icon={faTwitter} fixedWidth />
                                </a>

                                <a target="_blank" rel="noopener" href="https://www.tiktok.com/@nostwave">
                                    <FontAwesomeIcon icon={faTiktok} fixedWidth />
                                </a>
                            </div>
                        </div>
                        <div>
                            <div className="d-grid gap-2 mx-2 mx-sm-3">
                                <div className="fw-bold">Quick links</div>
                                <div className="clickable" onClick={() => setShowAboutUs(true)}>
                                    About us
                                </div>
                                <Link href={AppPath.popularTopics} passHref>
                                    <a>Popular topics</a>
                                </Link>
                                <Link href={AppPath.support} passHref prefetch={false}>
                                    <a>Support</a>
                                </Link>
                                <Link href={AppPath.termsAndConditions} passHref prefetch={false}>
                                    <a>Terms and conditions</a>
                                </Link>
                                <Link href={AppPath.privacyPolicy} passHref prefetch={false}>
                                    <a>Privacy</a>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <hr className="my-4" />

                    <div>© {year} Nostwave. All rights reserved.</div>
                </Container>
            </footer>
        </>
    );
};
