import axios, { CancelToken } from "axios";

import { SearchResultDto } from "../models/dto/searchResultDto";

export class SearchService {
    public async search(cancelToken: CancelToken, epochStartingYear: number, query: string) {
        return await axios.get<SearchResultDto>(`search/${epochStartingYear}`, {
            params: { query },
            cancelToken,
        });
    }
}

export const searchService = new SearchService();
