import React, { FC } from "react";

export const aboutUsTitle = "Welcome to Nostwave";

export const AboutUsText: FC = () => {
    return (
        <>
            <p>Nostwave is a time machine and social platform focused around the past and things that make us feel nostalgic.</p>
            <p>
                If old video games, movies, music, or computers bring back all your memories and feelings, it means this website is right
                for you!
            </p>
            <p>Join us now and experience a real journey to the past.</p>
        </>
    );
};
