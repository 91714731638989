import React, { FC } from "react";
import { Button, Modal } from "react-bootstrap";

import { AboutUsText, aboutUsTitle } from "../components/AboutUsText";

interface Props {
    show: boolean;
    onHide: () => void;
}

export const AboutUsModal: FC<Props> = ({ show, onHide }) => {
    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>{aboutUsTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <AboutUsText />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={onHide}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
