import { NextSeo } from "next-seo";
import React, { FC, ReactNode } from "react";
import { Container } from "react-bootstrap";

import { titleSuffix } from "../utils/seoUtils";
import { AppFooter } from "./navigation/AppFooter";
import { AppNavbar } from "./navigation/AppNavbar";

interface Props {
    children: ReactNode;
    title: string;
    description?: string;
    openGraph?: { type: "article" | "profile"; profile: { username: string } };
    noNavigation?: boolean;
    noIndex?: boolean;
    noArchive?: boolean;
    noTimeMachineNav?: boolean;
    noTitleSuffix?: boolean;
    fullPage?: boolean;
}

export const Layout: FC<Props> = ({
    children,
    title,
    description,
    openGraph,
    noNavigation,
    noIndex,
    noArchive,
    noTimeMachineNav,
    noTitleSuffix,
    fullPage,
}) => (
    <>
        <NextSeo
            title={title + (noTitleSuffix ? "" : titleSuffix)}
            defaultTitle="Nostwave"
            description={description}
            noindex={Boolean(noIndex)}
            robotsProps={{ noarchive: Boolean(noArchive) }}
            openGraph={{
                type: (openGraph && openGraph.type) || "article",
                title,
                description,
                site_name: "Nostwave",
                profile: openGraph && openGraph.profile ? { username: openGraph.profile.username } : null,
            }}
        />

        <div className="d-flex flex-column min-vh-100">
            {!noNavigation && (
                <header>
                    <AppNavbar noTimeMachineNav={noTimeMachineNav} />
                </header>
            )}

            {fullPage ? children : <Container className="flex-grow-1">{children}</Container>}

            {!noNavigation && <AppFooter />}
        </div>
    </>
);
