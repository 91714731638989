import Link, { LinkProps } from "next/link";
import { useRouter } from "next/router";
import React, { FC } from "react";

import { AppPath } from "../../appPath";
import { useUser } from "../authContext";

interface Props extends LinkProps {
    children: React.ReactNode;
}

export const PrivateLink: FC<Props> = (props) => {
    const { user } = useUser();
    const router = useRouter();
    const href = user ? props.href : AppPath.signIn(router.asPath);

    return <Link {...props} href={href} />;
};
