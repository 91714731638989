import { useRouter } from "next/router";

import { epochStartingYears } from "../utils/timeMachineUtils";

export function useYear() {
    const router = useRouter();
    if (router.query.year) {
        return +router.query.year;
    }
    return epochStartingYears[0];
}
