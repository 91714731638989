import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Link from "next/link";
import { useRouter } from "next/router";
import React, { FC, useRef } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";

import { useYear } from "../../hooks/useYear";
import { epochStartingYears, getEpochText } from "../../utils/timeMachineUtils";
import styles from "./TimeMachineNavbar.module.scss";

interface Epoch {
    id: number;
    name: string;
}

const epochs: Epoch[] = epochStartingYears.map((i) => ({
    id: i,
    name: getEpochText(i),
}));

export const TimeMachineNavbar: FC = () => {
    const yearsList = useRef<HTMLDivElement>();
    const router = useRouter();
    const year = useYear();

    // TODO animate
    function scroll(value: number) {
        yearsList.current.scrollLeft += value;
    }

    function renderYear(epoch: Epoch, index: number) {
        const active = epoch.id === year;
        return (
            <span key={index}>
                <Link href={router.asPath.replace(`/${year}/`, `/${epoch.id}/`)} passHref>
                    <a className={classNames("mx-2 clickable", active ? "text-primary fw-bold" : "text-secondary")}>{epoch.name}</a>
                </Link>
            </span>
        );
    }

    return (
        <Navbar className={`${styles["navbar-tm"]} navbar-light border-bottom`} bg="white" expand="lg">
            <Container>
                <div className="d-flex flex-row text-nowrap mw-100">
                    <div className="pe-3">Time machine</div>

                    <div className={`${styles.scroller} text-secondary pe-1`}>
                        <FontAwesomeIcon className="clickable" icon={faChevronLeft} fixedWidth onClick={() => scroll(-100)} />
                    </div>

                    <div ref={yearsList} className={`${styles.epochs} hide-scrollbar bullets`}>
                        {epochs.map(renderYear)}
                    </div>

                    <div className={`${styles.scroller} text-secondary ps-1`}>
                        <FontAwesomeIcon className="clickable" icon={faChevronRight} fixedWidth onClick={() => scroll(100)} />
                    </div>
                </div>
            </Container>
        </Navbar>
    );
};
