import { FC } from "react";

import styles from "./AppNavbar.module.scss";
import { MainNavbar } from "./MainNavbar";
import { TimeMachineNavbar } from "./TimeMachineNavbar";

export interface Props {
    noTimeMachineNav?: boolean;
}

export const AppNavbar: FC<Props> = ({ noTimeMachineNav }) => {
    return (
        <div className={`${styles["nav-shadow"]} mb-4 sticky-top`}>
            <MainNavbar />
            {!noTimeMachineNav && <TimeMachineNavbar />}
        </div>
    );
};
