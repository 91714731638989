import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { withErrorBoundary } from "@sentry/nextjs";
import axios, { CancelTokenSource } from "axios";
import Link from "next/link";
import React, { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";

import { AppPath } from "../../appPath";
import { topicNameMap } from "../../posts/models/views/topicView";
import { useYear } from "../hooks/useYear";
import { SearchedUserDto } from "../models/dto/searchedUserDto";
import { SearchResultDto } from "../models/dto/searchResultDto";
import { searchService } from "../services/searchService";
import { SEARCH_DELAY, SEARCH_QUERY_MAX_LENGTH } from "../utils/globalConst";
import { getEpochStartingYear } from "../utils/timeMachineUtils";
import { Avatar } from "./Avatar";
import styles from "./SearchBox.module.scss";

const textClass = "small text-secondary";
const linkClass = "d-block w-100";

let SearchBox: FC = () => {
    const year = useYear();
    const epochStartingYear = useSelector(getEpochStartingYear);
    const [isLoading, setIsLoading] = useState(false);
    const [value, setValue] = useState("");
    const [requestCancelToken, setRequestCancelToken] = useState<CancelTokenSource>(null);
    const [showResults, setShowResults] = useState(false);
    const [result, setResult] = useState<{ query: string; data: SearchResultDto }>();
    const inputRef = useRef<HTMLInputElement>();
    const timer = useRef<NodeJS.Timeout>();

    useEffect(() => {
        return stopTimer;
    }, []);

    function handleChange(event: ChangeEvent<HTMLInputElement>) {
        if (requestCancelToken) {
            requestCancelToken.cancel();
        }

        setIsLoading(false);
        stopTimer();
        setValue(event.target.value);

        const query = event.target.value.trim();
        if (query.length <= 2) {
            setShowResults(false);
            return;
        }

        if (query.length > 2) {
            timer.current = setTimeout(() => search(query), SEARCH_DELAY);
        }
    }

    async function search(query: string) {
        setIsLoading(true);
        const cancelTokenSource = axios.CancelToken.source();
        setRequestCancelToken(cancelTokenSource);
        try {
            const { data } = await searchService.search(cancelTokenSource.token, epochStartingYear, query);
            data.users = data.users.sort((a, b) => a.username.localeCompare(b.username));
            data.topics = data.topics.sort((a, b) => a.name.localeCompare(b.name));
            setResult({ query, data });
            setShowResults(true);
        } catch (error) {
            setShowResults(false);
        } finally {
            setRequestCancelToken(null);
            setIsLoading(false);
        }
    }

    function handleKeyDown(event: KeyboardEvent<HTMLInputElement>) {
        if (event.key === "Enter" && !showResults) {
            const query = value.trim();
            if (query.length > 2) {
                if (result.query !== query) {
                    search(query);
                } else {
                    setShowResults(true);
                }
            }
        }
    }

    function stopTimer() {
        if (timer && timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
    }

    function renderUser(user: SearchedUserDto) {
        return (
            <div key={user.username} className="text-truncate mt-2">
                <Link href={AppPath.userProfile(year, user.username)}>
                    <a className={linkClass}>
                        <Avatar src={user.avatarUrl} size="small" username={user.username} className="me-2" />
                        {user.username}
                    </a>
                </Link>
            </div>
        );
    }

    function clear() {
        setValue("");
        setShowResults(false);
    }

    function renderIcon() {
        if (isLoading) {
            return <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" />;
        }

        if (value) {
            return <FontAwesomeIcon className="clickable" onClick={clear} icon={faTimes} fixedWidth />;
        }

        return <FontAwesomeIcon icon={faSearch} fixedWidth />;
    }

    return (
        <div className={styles["search-box"]}>
            {showResults && <div onClick={() => setShowResults(false)} className={styles["results-overlay"]}></div>}

            <div onClick={() => inputRef.current.focus()} className={`${styles["search-pill"]} rounded-pill shadow-sm py-1 px-3 bg-light`}>
                <div className={styles["search-status-icon"]}>{renderIcon()}</div>
                <input
                    ref={inputRef}
                    value={value}
                    maxLength={SEARCH_QUERY_MAX_LENGTH}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    className="p-0 bg-light"
                    type="text"
                    placeholder="Search..."
                />
            </div>
            {showResults && !isLoading && (
                <div className={`${styles.resultsContainer} w-100 bg-white position-absolute shadow-sm border-bottom`}>
                    <div className={`${styles.results} px-3 pb-3 text-body`}>
                        {result.data.users.length > 0 && (
                            <>
                                <div className={textClass}>Users</div>
                                {result.data.users.map((i) => renderUser(i))}
                            </>
                        )}

                        {result.data.users.length > 0 && result.data.topics.length > 0 && <hr />}
                        {result.data.users.length === 0 && result.data.topics.length === 0 && (
                            <div className={textClass}>No results :(</div>
                        )}

                        {result.data.topics.length > 0 && (
                            <>
                                <div className={textClass}>Topics</div>
                                {result.data.topics.map((i) => (
                                    <div className="mt-2 text-truncate" key={i.id}>
                                        <Link href={AppPath.topic(i)} passHref>
                                            <a className={linkClass}>
                                                <div>{i.name}</div>
                                                <div className="topic-subtitle">
                                                    {topicNameMap[i.category]} ({i.year})
                                                </div>
                                            </a>
                                        </Link>
                                    </div>
                                ))}
                            </>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

SearchBox = withErrorBoundary(SearchBox, { fallback: <></> });
export { SearchBox };
