import { TopicCategory } from "../../posts/models/enums/topicCategory";

export abstract class TopicDto {
    id: string;
    name: string;
    hasPage: boolean;
    year: number;
    endYear: number;
    category: TopicCategory;
    epochStartingYear: number;
    featuredImageUrl: string;
}
