export const epochStartingYears = [2021, 2016, 2011, 2006, 2001, 1996, 1991, 1986, 1981, 1976, 1970];

const endingYears: Record<number | string, number> = {
    2021: 2025,
    2016: 2020,
    2011: 2015,
    2006: 2010,
    2001: 2005,
    1996: 2000,
    1991: 1995,
    1986: 1990,
    1981: 1985,
    1976: 1980,
    1970: 1975,
};

export const defaultEpochStartingYear = 2021;

export function getEpochEndingYear(start: number | string): number {
    return endingYears[start] || -1;
}

export function getEpochText(start: number | string): string {
    return start + "-" + getEpochEndingYear(start);
}

export function getEpochStartingYear(year: number): number {
    if (year >= 1970 && year <= 1975) {
        return 1970;
    }

    for (let i = 1976; i < 2050; i += 5) {
        if (year >= i && year <= i + 4) {
            return i;
        }
    }
    return defaultEpochStartingYear;
}
